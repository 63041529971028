import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeC from '../../components/Heroes/HeroTypeC';
import BannerTypeColored from '../../components/Banners/BannerTypeColored';
import BannerInfoTypeB from '../../components/Banners/BannerInfoTypeB';
import TripleBanner from '../../components/Location/TripleBanner';
import MultiArticleV2 from '../../components/Articles/MultiArticleV2';
import FaqSection from '../../components/FAQ/FaqSection';
import BannerTypeA from '../../components/Banners/BannerTypeA';
import SEO from '../../components/SEO/SEO';

export default function KeswickDelivery({ data }) {
  const hero_type_c = data.strapiKeswickDelivery.Hero;
  const info_banner_colored = data.strapiKeswickDelivery.InfoBannerA;
  const info_banner_type_a = data.strapiKeswickDelivery.InfoBannerB;
  const triple_banner = data.strapiKeswickDelivery.TripleInfoBanner;
  const multi_article = data.strapiKeswickDelivery.MultiArticle;
  const faq = data.strapiKeswickDelivery.Faq;
  const banner_type_bottom = data.strapiKeswickDelivery.BlogBanner;

  return (
    <>
      <SEO
        title="Weed Delivery in Keswick | House of Cannabis"
        description="If you’re not interested in going out, you no 
                  longer have to go without amazing House of Cannabis products. 
                  We’re now offering weed delivery in Keswick!"
      />
      <HeroTypeC
        title={hero_type_c.Title}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />

      <BannerTypeColored
        description={info_banner_colored.Content}
        buttonLabel="SHOP NOW"
        buttonLink="/keswick/menu"
        center
      />

      <BannerInfoTypeB
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.BackgroundImage.localFile.childImageSharp
            .gatsbyImageData
        }
        center
      />

      <TripleBanner
        title={triple_banner.Title}
        banners={triple_banner.Single_Triple_Info_Banner}
      />

      <MultiArticleV2
        leftarticle={multi_article.Left_Mutli_Article}
        rightarticle={multi_article.Right_Multi_Article}
      />

      <FaqSection faqdata={faq.FAQs} title={faq.Title} />

      <BannerTypeA
        title={banner_type_bottom.Article_Link.Title}
        description={banner_type_bottom.Article_Link.Preview_Text}
        bannerImage={
          banner_type_bottom.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />
    </>
  );
}

/* {`/blog/${banner_type_bottom.Article_Link.slug}`} */

export const query = graphql`
  query {
    strapiKeswickDelivery {
      BlogBanner {
        Article_Link {
          Title
          slug
          Preview_Text
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
      Faq {
        Title
        FAQs {
          Question
          Answer
        }
      }
      Hero {
        Content
        Title
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
      InfoBannerA {
        Content
      }
      InfoBannerB {
        Content
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
      MultiArticle {
        Left_Mutli_Article {
          article {
            Title
            Preview_Text
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
        }
        Right_Multi_Article {
          article {
            Title
            Preview_Text
            slug
          }
        }
      }
      TripleInfoBanner {
        Single_Triple_Info_Banner {
          Content
          Header
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        Title
      }
    }
  }
`;
